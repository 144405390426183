<template>
  <perfect-scrollbar
    class="quick-search-wrapper scroll"
    style="max-height: 40vh; position: relative"
    v-bind:options="{ suppressScrollX: true }"
  >
    <div class="quick-search-result">
      <!--begin::Message-->
      <div v-if="!data" class="text-muted d-none">No record found</div>
      <div
          v-if="data"
          @click="setCompany(null)"
          class="font-size-sm text-primary font-weight-bolder text-uppercase mb-2 cursor-pointer">
        Clear Company
      </div>
      <!--end::Message-->
      <template v-for="(item, i) in data">
        <!--begin::Section-->
        <div
          :key="item.id + '-company-' + i"
          @click="setCompany(item)"
          class="font-size-sm text-primary font-weight-bolder text-uppercase mb-2 cursor-pointer"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
  </perfect-scrollbar>
</template>

<script>
export default {
  name: "KTSearchResult",
  props: {
    data: Array,
  },
  methods: {
    setCompany(item) {
      this.$store.commit('setCompanyFilter', item);
      this.$store.dispatch('getDashboardInfo');
      if (this.$route.name != "dashboard") {
        this.$router.push({name: "dashboard"});
      }
    }
  }
};
</script>
