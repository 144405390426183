<template>
  <div class="topbar-item d-flex justify-content-end" style="width: 300px">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hi, {{currentUser ? currentUser.first_name : ''}}
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >

      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <span class="symbol-label font-size-h5 font-weight-bold">
          {{currentUser ? currentUser.first_name.charAt(0)  : 'M' }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
          v-if="currentUser"
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold">
              {{currentUser ? currentUser.first_name.charAt(0)  : 'M' }}
            </span>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{currentUser ? currentUser.first_name : ''}} {{currentUser ? currentUser.last_name  : ''}}
            </a>
            <div v-if="roles.find(v => v.id === currentUser.role)" class="text-muted mt-1">{{roles.find(v => v.id === currentUser.role).name}}</div>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <div>
          <div class="navi mt-2 d-flex align-center align-items-center justify-content-center">
            <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                          src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span v-if="currentUser" class="navi-text text-muted text-hover-primary">
                    {{currentUser.email}}
                  </span>
                </span>
            </a>
          </div>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <div>
          <router-link
              to="/account/change-password"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >

            <div class="navi mt-2 d-flex align-center align-items-center justify-content-center">
              <button class="btn btn-light-primary btn-bold w-100" @click="navigate">
                Change Password
              </button>
            </div>
          </router-link>


          <div class="navi mt-2 d-flex align-center align-items-center justify-content-center">
            <button class="btn btn-light-primary btn-bold w-100" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>

      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import {mapGetters} from "vuex";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning",
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success",
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger",
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info",
        },
      ],
      roles: [],
    };
  },
  watch: {
    currentUser: {
      handler: function() {
        const self = this;
        setTimeout(() => {
          KTLayoutQuickUser.init(self.$refs["kt_quick_user"]);
        }, 500);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // Init Quick User Panel
    this.getRoles();
  },
  methods: {
    onLogout() {
      this.$store.dispatch("signOutWithFirebase");
      /*
      .then(() => {
        this.$router.push({ name: "login" });
      });
      */
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    async getRoles() {
      const d = await this.$store.dispatch("getList", {
        target: "roles",
        filters: [],
        page: 1,
        pageLimit: 50,
        sortBy: null,
        sortOrder: null,
        query: { targets: ["name"], value: "" },
      });

      this.roles = d.list;
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
