<template>
  <div
    v-bind:class="{ 'quick-search-has-result': hasResult() }"
    class="quick-search quick-search-dropdown quick-search-result-compact"
    id="kt_quick_search_dropdown"
  >
    <form v-if="mandatoryCompany == null" method="get" class="quick-search-form">
      <div
        class="input-group spinner-input spinner-sm spinner-brand spinner-right"
        v-bind:class="{ spinner: loading }"
      >
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="flaticon2-search-1"></i>
          </span>
        </div>
        <input
          v-on:keyup="onSearch"
          v-model="search"
          type="text"
          class="form-control quick-search-input"
          placeholder="Search..."
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i
              class="quick-search-close"
              v-on:click="reset"
              v-bind:style="{ display: hasResult() ? 'flex' : '' }"
            ></i>
          </span>
        </div>
      </div>
    </form>

    <KTSearchResult v-bind:data="data"></KTSearchResult>
  </div>
</template>

<script>
import KTSearchResult from "@/view/layout/extras/dropdown/SearchResult.vue";
import {mapGetters} from "vuex";

export default {
  name: "KTSearchDefault",
  components: { KTSearchResult },
  watch: {
    companyAdd(val) {
      if (val) {
        this.getData();
      }
    },
    currentUser: {
      handler: function(val) {
        if (val) {
          this.getData();
        }
      },
      deep: true,

    }
  },
  data() {
    return {
      data: [],
      loading: false,
      writingTimeout: null,
      search: "",
      // dummy search result data
      result: [

      ],
    };
  },
  mounted() {
    this.getData();
  },
  computed : {
    ...mapGetters(["mandatoryCompany", "companyAdd", "currentUser"])
  },
  methods: {
    async getData() {
      if (this.mandatoryCompany) {
        const d = await this.$store.dispatch("getData", "companies/" + this.mandatoryCompany);
        this.result = [d];
        this.$store.commit("setCompanyFilter", this.result[0]);
      } else {
        const d = await this.$store.dispatch("getListWithoutFilter", {
          target: "companies",
          filters: [{field: "phone", filter: "==", value: ""}],
          page: 1,
          pageLimit: 100,
          sortBy: "name",
          sortOrder: 'asc',
          query: {targets: ['name'], value: this.search},
          last: 0,
          prev: 0,
        });

        this.result = d ? d.list : [];
      }


    },
    onSearch() {
      var self = this;
      self.loading = true;
      clearTimeout(self.writingTimeout);
      self.searchResult = [];
      if(self.search != null){

        this.writingTimeout = setTimeout(function () {
          self.filterResult();
          self.loading = false;
        }, 750);
      } else {
        self.loading = false;
      }
    },
    filterResult() {
      this.data = this.result.filter(v => {
        if (v.name) {
          return v.name.toLowerCase().includes(this.search.toLowerCase());
        } else {
          return false;
        }
      });
    },
    /**
     * Check if the data has result
     * @returns {boolean}
     */
    hasResult() {
      return this.data.length || false;
    },
    /**
     * Reset search data
     */
    reset() {
      this.data = [];
    },
  },
};
</script>
